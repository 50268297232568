module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-5VZD3T6RF6","AW-16631820327"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Grona: Soluciones Agropecuarias","short_name":"Grona","start_url":"/","lang":"es","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"src/assets/icons/favicon-32x32.png","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicon-32x32.png","sizes":"32x32","type":"image/png"}],"localize":[{"start_url":"/en/","lang":"en","name":"Grona: Agricultural Solutions","short_name":"Grona","description":"Grona offers agricultural solutions specializing in pond design, irrigation systems, rebombing, PVC pipes, and heavy machinery rental."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2e0d363538c89663f1373910a322d7c2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es"],"defaultLanguage":"es","siteUrl":"https://grona.com.mx","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":"."},"pages":[{"matchPath":"/:lang?/404/","getLanguageFromPath":true},{"matchPath":"/:lang?/maintenance/","getLanguageFromPath":true}],"redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
