import React from "react";
import { AnalyticsProvider } from "./src/context/AnalyticsContext/AnalyticsContext";
import { navigate } from "gatsby";

export const onInitialClientRender = () => {
  if (typeof window !== "undefined") {
    const userLang = navigator.language;
    const isMaintenance = process.env.GATSBY_SITE_MAINTENANCE === "true";

    // Maintenance redirection
    if (isMaintenance) {
      if (userLang.startsWith("en")) {
        navigate("/en/maintenance", { replace: true });
      } else {
        navigate("/maintenance", { replace: true });
      }
    }
  }
};

export const wrapRootElement = ({ element }) => {
  return <AnalyticsProvider>{element}</AnalyticsProvider>;
};
